import { Component, Vue, Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Organization } from '@/models/Organization';
import { cloneDeep } from 'lodash';
import { User, getUserTypeName, userLevelLabels } from '@/models/User';
import { Skill } from '@/models/Skill';
import { UserLevelItem, UserLevels } from '@/models/User';

@Component<BlockUsers>({})
export default class BlockUsers extends Vue {
  public $pageTitle = 'Deskundige lijst';

  protected isLoading = true;

  protected activeTab = '';

  protected organizations: Organization[] | null = null;

  protected users: User[] | null = null;

  protected skills: Skill[] = [];

  protected selectedSkills: Skill[] | null = null;

  protected userLevels: UserLevelItem[] = UserLevels;

  protected selectedLevels: string[] = [];

  public mounted() {
    this.emitBreadcrumb();
    this.initialize();
  }

  protected initialize() {
    if (this.$store.state.isServiceOrganization) {
      this.getOrganizations();
    } else {
      this.organizations = [cloneDeep(this.$store.state.Auth.organization)];
      this.activeTab = this.organizations[0].id as string;
      this.fetchUsers(this.organizations[0].id as string);
    }

    this.getSkills();
  }

  protected getSkills() {
    new Skill()
      .all()
      .then((skills: Skill[]) => {
        this.skills = skills;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getOrganizations() {
    new Organization()
      .getAllExperts()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;

        if (organizations && organizations.length) {
          this.activeTab = this.organizations[0].id as string;
          this.fetchUsers(this.organizations[0].id as string);
        }
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected fetchUsers(id: string): void {
    this.isLoading = true;
    const payload: any = {
      is_active: true,
      is_plannable: true,
    };

    if (this.selectedSkills) {
      payload.skills = this.selectedSkills;
    }

    if (this.selectedLevels) {
      payload.levels = this.selectedLevels;
    }

    new User()
      .dmz(id)
      .limit(500)
      .sort('name', 'ASC')
      .filter(payload)
      .include(['skills', 'last_availablity_slot'])
      .all()
      .then((users: User[]) => {
        this.users = users;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected updateUser(user: User, id: string) {
    if (! user) {
      return;
    }

    user.dmz(this.activeTab)
      .update({
        is_blocked_by_planning: user.is_blocked_by_planning,
      })
      .then(() => {
        this.fetchUsers(this.activeTab);
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getUserTypeName(user : User) {
    if (! user || ! user.type) {
      return '';
    }

    return getUserTypeName(user.type);
  }

  protected getAvailabilityColor(daysAgo: number) {
    if (! daysAgo) { return; }

    if (daysAgo >= 28 && daysAgo < 56) {
      return 'orange';
    }

    if (daysAgo >= 56) {
      return 'red';
    }
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Deskundige lijst ' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }

  protected get userLevelLabels() {
    return userLevelLabels;
  }
}

interface tcmgSetting {
  key: string;
  type: string;
  value: any;
  label: string;
  description?: string;
  menu?: boolean;
  visible?: boolean;
}
