var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Deskundige lijst")
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md12: "" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "slider-color": "secondary" },
                  model: {
                    value: _vm.activeTab,
                    callback: function($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab"
                  }
                },
                [
                  _vm._l(_vm.organizations, function(organization, index) {
                    return [
                      _c(
                        "v-tab",
                        {
                          key: "planning-tab-" + index,
                          staticClass: "tab__filled",
                          class: { active: _vm.activeTab === organization.id },
                          attrs: { href: "#" + organization.id },
                          on: {
                            click: function($event) {
                              _vm.fetchUsers(organization.id)
                              _vm.isLoading = true
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(organization.name) + " ")]
                      )
                    ]
                  }),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _vm._l(_vm.organizations, function(organization) {
                        return [
                          _c(
                            "v-tab-item",
                            {
                              key: "planning-tab-content-" + organization.name,
                              attrs: {
                                lazy: true,
                                transition: false,
                                "reverse-transition": false,
                                value: organization.id
                              }
                            },
                            [
                              _vm.activeTab === organization.id
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "elementPanel",
                                      attrs: { row: "", wrap: "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { shrink: "" } },
                                            [
                                              _c("v-autocomplete", {
                                                staticClass: "with-border",
                                                attrs: {
                                                  items: _vm.skills,
                                                  "item-value": "id",
                                                  "item-text": "name",
                                                  "hide-details": "",
                                                  label: "Competenties",
                                                  clearable: "",
                                                  multiple: ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.fetchUsers(
                                                      _vm.activeTab
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        var index = ref.index
                                                        return [
                                                          index === 0
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "Competenties (" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .selectedSkills
                                                                        .length
                                                                    ) +
                                                                    ")"
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.selectedSkills,
                                                  callback: function($$v) {
                                                    _vm.selectedSkills = $$v
                                                  },
                                                  expression: "selectedSkills"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { shrink: "" } },
                                            [
                                              _c("v-select", {
                                                staticClass:
                                                  "with-border pt-0 mt-0",
                                                attrs: {
                                                  "prepend-icon": "poll",
                                                  items: _vm.userLevels,
                                                  "item-text": "name",
                                                  "item-value": "value",
                                                  label: "Niveaus",
                                                  multiple: ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.fetchUsers(
                                                      _vm.activeTab
                                                    )
                                                  }
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "selection",
                                                      fn: function(ref) {
                                                        var item = ref.item
                                                        var index = ref.index
                                                        return [
                                                          index === 0
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  "Niveaus (" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .selectedLevels
                                                                        .length
                                                                    ) +
                                                                    ")"
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.selectedLevels,
                                                  callback: function($$v) {
                                                    _vm.selectedLevels = $$v
                                                  },
                                                  expression: "selectedLevels"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.isLoading
                                        ? _c("LoaderCard", {
                                            attrs: {
                                              flat: "",
                                              type: "spinner--center",
                                              minHeight: "300px"
                                            }
                                          })
                                        : _vm._e(),
                                      !_vm.isLoading && _vm.users
                                        ? _c(
                                            "v-layout",
                                            {
                                              staticClass: "item__list",
                                              attrs: { wrap: "" }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass: "label",
                                                  attrs: { xs12: "" }
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs3: "" } },
                                                        [_vm._v("Naam")]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs2: "" } },
                                                        [_vm._v("Niveau")]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs4: "" } },
                                                        [_vm._v("Competenties")]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs2: "" } },
                                                        [
                                                          _vm._v(
                                                            "Blokeer gebruiker"
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs1: "",
                                                            "text-xs-center": ""
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Niet beschikbaar"
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._l(_vm.users, function(
                                                user,
                                                index
                                              ) {
                                                return [
                                                  user
                                                    ? _c(
                                                        "v-flex",
                                                        {
                                                          key: index,
                                                          staticClass:
                                                            "list__item",
                                                          attrs: { xs12: "" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: "",
                                                                "align-center":
                                                                  "",
                                                                "justify-space-between":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs3: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          user.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "item__sub"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.getUserTypeName(
                                                                              user
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ])
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs2: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .userLevelLabels[
                                                                          user
                                                                            .level
                                                                        ]
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs4: ""
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  user.skills,
                                                                  function(
                                                                    skill,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "v-chip",
                                                                      {
                                                                        key:
                                                                          "skill-" +
                                                                          index
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                skill.name
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs2: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm.$store
                                                                    .state.Auth
                                                                    .is_planner
                                                                    ? _c(
                                                                        "v-switch",
                                                                        {
                                                                          staticClass:
                                                                            "custom_switch",
                                                                          attrs: {
                                                                            color:
                                                                              "#837f16",
                                                                            "hide-details":
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.updateUser(
                                                                                user,
                                                                                organization.id
                                                                              )
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              user.is_blocked_by_planning,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                user,
                                                                                "is_blocked_by_planning",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "user.is_blocked_by_planning"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "v-icon",
                                                                        {
                                                                          attrs: {
                                                                            color: user.is_blocked_by_planning
                                                                              ? "warning"
                                                                              : ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                user.is_blocked_by_planning
                                                                                  ? "close"
                                                                                  : ""
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs1: "",
                                                                    "text-xs-center":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  user
                                                                    .last_availablity_slot
                                                                    .days_ago
                                                                    ? _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  return [
                                                                                    _c(
                                                                                      "span",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "availablity_slot",
                                                                                          class: _vm.getAvailabilityColor(
                                                                                            user
                                                                                              .last_availablity_slot
                                                                                              .days_ago
                                                                                          )
                                                                                        },
                                                                                        on
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            true
                                                                          )
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  user
                                                                                    .last_availablity_slot
                                                                                    .days_ago
                                                                                ) +
                                                                                  " dagen niet beschikbaar"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ]
                                              })
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }